/* eslint-disable no-console */
import "./FundingCalculatorForm.css";

import {
    Button,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";

import inputNumberIsEmpty from "../../utils/form/inputNumberIsEmpty";
import stringIsEmpty from "../../utils/form/stringIsEmpty";
import { IFundingTypes, fundingTypes } from "./helper/data";
import { ICalculateKFWResult, calculateKFW } from "./helper/formulas";
import { handleErrors } from "./helper/handleErrors";

interface IFundingData {
    eligibleCosts: number;
    fundingCalculatorType: string;
}

interface FundingCalculatorProps {
    onClick?: () => void;
}

const InitialCalculateKFWResult = {
    basicSupport: "",
    basicFundingCap: "",
    bonusPromotion: "",
    percentOfEligibleCosts: "",
};

const InitialFundingData = {
    eligibleCosts: 0,
    fundingCalculatorType: "",
};

const FundingCalculator = ({ onClick }: FundingCalculatorProps) => {
    const [fundingCalculationInputs, setFundingCalculationInputs] =
        useState<IFundingData>({ ...InitialFundingData });

    const [fundingCalculationResult, setFundingCalculationResult] =
        useState<ICalculateKFWResult>({ ...InitialCalculateKFWResult });
    const [errorMessage, setErrorMessage] = useState<string>("");

    const changeInputEligibleCosts = (value: any) => {
        setFundingCalculationInputs({
            ...fundingCalculationInputs,
            eligibleCosts: parseFloat(value),
        });
    };

    const changeInput = (event: any) => {
        setFundingCalculationInputs({
            ...fundingCalculationInputs,
            [event.target.name]: event.target.value,
        });
    };

    const handleCalculation = () => {
        const res: ICalculateKFWResult = calculateKFW(
            Number(fundingCalculationInputs.eligibleCosts),
            fundingCalculationInputs.fundingCalculatorType
        );
        setFundingCalculationResult(res);
        const error =
            Number(fundingCalculationInputs.eligibleCosts) > 0 &&
            fundingCalculationInputs.fundingCalculatorType !== ""
                ? handleErrors(
                      Number(fundingCalculationInputs.eligibleCosts),
                      fundingCalculationInputs.fundingCalculatorType
                  )
                : "";
        setErrorMessage(error);
    };

    const buttonIsDisabled =
        inputNumberIsEmpty(fundingCalculationInputs.eligibleCosts) ||
        stringIsEmpty(fundingCalculationInputs.fundingCalculatorType);

    return (
        <div>
            <Typography
                variant="h2"
                className="gap-bottom calculator-header"
                onClick={onClick}
            >
                Fördermittelrechner
            </Typography>
            <div className="calculator-content-container">
                <Grid
                    container
                    md={4}
                    lg={4}
                    xs={12}
                    className="calculator-content-container__calculation-section with-shadow"
                >
                    <div>
                        <Grid
                            item
                            xs={12}
                            className="calculator-content-container__calculation-section-mobile"
                        >
                            <Typography className="main-header gap-bottom">
                                Anrechenbare Kosten
                            </Typography>
                            <div className="currency-input MuiFormControl-root MuiTextField-root with-text-field-border-radius">
                                <CurrencyInput
                                    className="MuiInputBase-input MuiInputBase-inputAdornedEnd MuiOutlinedInput-inputAdornedEnd"
                                    id="Förderfähige Kosten"
                                    name="eligibleCosts"
                                    required={true}
                                    decimalSeparator=","
                                    groupSeparator="."
                                    onValueChange={(value) => {
                                        setFundingCalculationResult(
                                            InitialCalculateKFWResult
                                        );
                                        changeInputEligibleCosts(value);
                                    }}
                                    allowNegativeValue={false}
                                    intlConfig={{
                                        locale: "de-DE",
                                    }}
                                />
                                <InputAdornment
                                    position="start"
                                    className="calculator-content-container__calculation-section__unit-symbol"
                                >
                                    €
                                </InputAdornment>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className="calculator-content-container__calculation-section-mobile"
                        >
                            <Typography className="main-header gap-bottom">
                                KFW Standard
                            </Typography>
                            <Select
                                id="fundingCalculatorType"
                                value={
                                    fundingCalculationInputs.fundingCalculatorType
                                }
                                fullWidth
                                onChange={(e) => {
                                    changeInput(e);
                                }}
                                variant="outlined"
                                required={true}
                                name="fundingCalculatorType"
                                className="calculator-content-container__kfw-types"
                            >
                                {fundingTypes.map((type: IFundingTypes) => {
                                    return (
                                        <MenuItem
                                            key={type.id}
                                            value={type.label}
                                        >
                                            {type.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                    </div>
                    <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        onClick={handleCalculation}
                        className={`${
                            !buttonIsDisabled ? "button--colored" : ""
                        } calculator-content-container__calculation-section__edit-button`}
                        disabled={buttonIsDisabled}
                    >
                        Berechnen
                    </Button>
                </Grid>
                <Grid
                    container
                    className="calculator-content-container__result-section"
                >
                    <Grid item xs={12}>
                        <Typography className="main-header gap-bottom">
                            Förderung
                        </Typography>
                        <div className="currency-input MuiFormControl-root MuiTextField-root with-shadow with-text-field-border-radius">
                            <CurrencyInput
                                className="MuiInputBase-input MuiInputBase-inputAdornedEnd MuiOutlinedInput-inputAdornedEnd"
                                name="basicSupport"
                                value={fundingCalculationResult.basicSupport}
                                disabled
                                decimalSeparator=","
                                groupSeparator="."
                                intlConfig={{
                                    locale: "de-DE",
                                }}
                            />
                            <InputAdornment
                                position="start"
                                className="calculator-content-container__calculation-section__unit-symbol"
                            >
                                €
                            </InputAdornment>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className="main-header gap-bottom">
                            Deckel der Förderung
                        </Typography>
                        <div className="currency-input MuiFormControl-root MuiTextField-root with-shadow with-text-field-border-radius">
                            <CurrencyInput
                                className="MuiInputBase-input MuiInputBase-inputAdornedEnd MuiOutlinedInput-inputAdornedEnd"
                                name="basicFundingCap"
                                value={fundingCalculationResult.basicFundingCap}
                                disabled
                                decimalSeparator=","
                                groupSeparator="."
                                intlConfig={{
                                    locale: "de-DE",
                                }}
                            />
                            <InputAdornment
                                position="start"
                                className="calculator-content-container__calculation-section__unit-symbol"
                            >
                                €
                            </InputAdornment>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className="main-header gap-bottom">
                            Prozent der anrechenbaren Kosten
                        </Typography>

                        <TextField
                            className="with-shadow with-text-field-border-radius percentage-field"
                            variant="outlined"
                            name="percentOfEligibleCosts"
                            value={
                                fundingCalculationResult.percentOfEligibleCosts
                            }
                            required={true}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="start"
                                        className="calculator-content-container__result-section__percentage-symbol"
                                    >
                                        %
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    {!stringIsEmpty(errorMessage) && (
                        <Grid
                            item
                            xs={12}
                            className={`calculator-content-container__result-section__error-message`}
                        >
                            <Typography className="calculator-content-container__result-section__error-message__content">
                                {!stringIsEmpty(errorMessage)
                                    ? errorMessage
                                    : ""}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} className={"gap-top"}>
                        <Typography variant="caption">
                            Alle Angaben ohne Gewähr.
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default FundingCalculator;
