import { Alert } from "@material-ui/lab";
import React from "react";

import { CmsContentBlock } from "../../types/CmsPageData";
import { CmsRenderContentAttachment } from "./contentTypes/CmsRenderContentAttachment";
import { CmsRenderContentAttachmentList } from "./contentTypes/CmsRenderContentAttachmentList";
import { CmsRenderContentCalculator } from "./contentTypes/CmsRenderContentCalculator";
import { CmsRenderContentComments } from "./contentTypes/CmsRenderContentComments";
import { CmsRenderContentFaq } from "./contentTypes/CmsRenderContentFaq";
import { CmsRenderContentHeader } from "./contentTypes/CmsRenderContentHeader";
import { CmsRenderContentHtml } from "./contentTypes/CmsRenderContentHtml";
import { CmsRenderContentImage } from "./contentTypes/CmsRenderContentImage";
import { CmsRenderContentMapComments } from "./contentTypes/CmsRenderContentMapComments";
import { CmsRenderContentPerson } from "./contentTypes/CmsRenderContentPerson";
import { CmsRenderContentPersonList } from "./contentTypes/CmsRenderContentPersonList";
import { CmsRenderContentText } from "./contentTypes/CmsRenderContentText";

export interface CmsRenderContentProps {
    content: CmsContentBlock;
}

export function CmsRenderContentMapper({ content }: CmsRenderContentProps) {
    switch (content.type) {
        case "header_1": {
            return <CmsRenderContentHeader variant={"h1"} content={content} />;
        }
        case "header_2": {
            return <CmsRenderContentHeader variant={"h2"} content={content} />;
        }
        case "header_3": {
            return <CmsRenderContentHeader variant={"h3"} content={content} />;
        }
        case "header_4": {
            return <CmsRenderContentHeader variant={"h4"} content={content} />;
        }
        case "image": {
            return <CmsRenderContentImage content={content} />;
        }
        case "text": {
            return <CmsRenderContentText content={content} />;
        }
        case "html": {
            return <CmsRenderContentHtml content={content} />;
        }
        case "person": {
            return <CmsRenderContentPerson content={content} />;
        }
        case "person_list": {
            return <CmsRenderContentPersonList content={content} />;
        }
        case "attachment": {
            return <CmsRenderContentAttachment content={content} />;
        }
        case "attachment_list": {
            return <CmsRenderContentAttachmentList content={content} />;
        }
        case "calculator": {
            return <CmsRenderContentCalculator />;
        }
        case "faq": {
            return <CmsRenderContentFaq content={content} />;
        }
        case "comments": {
            return <CmsRenderContentComments />;
        }
        case "map_comments": {
            return <CmsRenderContentMapComments />;
        }
        default: {
            return <Alert severity={"error"}>Missing Content Type: {content.type}</Alert>;
        }
    }
}
