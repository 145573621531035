import "./FileUpload.css";

import { TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Fab from "@material-ui/core/Fab";
import AttachmentIcon from "@material-ui/icons/Attachment";
import CancelIcon from "@material-ui/icons/Cancel";
import React from "react";

import { API_URL_STATIC } from "../../config/config";
import IDocument from "../../types/IDocument";
import InitialDocumentData from "../../types/initialData/InitialDocumentData";
import { formatBytes } from "../../utils/bytesConverter";

interface FileUploadCmsProps {
    name: string | number;
    label: string;
    docData: Partial<IDocument>;
    defaultFilename: string;
    onChange: (image: Partial<IDocument>) => void;
    errorState?: boolean;
    disabled?: boolean;
}

export const FileUploadCms = (props: FileUploadCmsProps) => {
    const { docData, onChange, errorState, disabled } = props;

    const handleUploadClick = (event: any) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // const url =

        reader.onloadend = () => {
            onChange({
                ...docData,
                data: reader.result,
                name: file.name,
                size: file.size,
            });
        };
    };

    const handleResetClick = () => {
        onChange({
            ...InitialDocumentData,
            id: docData.id,
            type: docData.type,
            key: docData.key,
        });
    };

    const getUploadSrc = (upload: Partial<IDocument>) => {
        if (upload?.data) {
            return upload.data;
        }
        if (upload?.url) {
            return API_URL_STATIC + upload.url;
        }

        return null;
    };

    const handleChange = (event: any) => {
        const newData = { ...docData, [event.target.name]: event.target.value };
        onChange(newData);
    };

    const imageSource = getUploadSrc(docData);

    const sizeIsNotNull = (size?: number) => {
        if (size == null) {
            return false;
        }

        return size > 0;
    };

    return (
        <Card
            variant="outlined"
            className="upload-card"
            aria-disabled={disabled}
        >
            <CardActionArea disableRipple={true}>
                <CardMedia title={props.label}>
                    <></>
                </CardMedia>
                <CardContent>
                    {errorState ? (
                        <Typography
                            variant="h3"
                            className={"gap-top gap-bottom"}
                            style={{ color: "red" }}
                        >
                            {props.label}
                        </Typography>
                    ) : (
                        <Typography
                            variant="h3"
                            className={"gap-top gap-bottom"}
                        >
                            {props.label}
                        </Typography>
                    )}

                    <div className="gap-bottom">
                        <Typography align="left" component="div">
                            {docData?.name}
                        </Typography>

                        {sizeIsNotNull(docData?.size) && (
                            <Typography align="left">
                                Dateigröße : {formatBytes(docData?.size)}
                            </Typography>
                        )}
                    </div>

                    <input
                        accept="image/*"
                        id={"contained-button-file" + props.name}
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleUploadClick}
                        disabled={disabled}
                    />
                    <label htmlFor={"contained-button-file" + props.name}>
                        {!imageSource && (
                            <Fab
                                component="span"
                                style={{
                                    marginTop: "16px",
                                    marginLeft: "16px",
                                    marginBottom: "16px",
                                }}
                            >
                                <AttachmentIcon />{" "}
                            </Fab>
                        )}
                    </label>
                    {imageSource && (
                        <>
                            <TextField
                                name="name"
                                label="Dateiname"
                                fullWidth
                                value={
                                    docData?.name
                                        ? docData?.name
                                        : props.defaultFilename
                                }
                                onChange={handleChange}
                                variant="outlined"
                            />
                            <TextField
                                name="title"
                                label="Titel"
                                fullWidth
                                value={docData?.title ? docData?.title : ""}
                                onChange={handleChange}
                                variant="outlined"
                            />
                            <TextField
                                name="description"
                                label="Beschreibung"
                                fullWidth
                                value={
                                    docData?.description
                                        ? docData?.description
                                        : ""
                                }
                                onChange={handleChange}
                                variant="outlined"
                            />
                            <TextField
                                name="copyright"
                                label="Copyright *"
                                fullWidth
                                value={
                                    docData?.copyright ? docData?.copyright : ""
                                }
                                onChange={handleChange}
                                variant="outlined"
                            />
                        </>
                    )}
                </CardContent>
            </CardActionArea>
            {imageSource && (
                <CardActions>
                    <Button
                        onClick={handleResetClick}
                        color="secondary"
                        startIcon={<CancelIcon />}
                    >
                        Datei entfernen
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};
