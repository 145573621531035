import React, { useCallback, useEffect } from "react";
import uuid from "uuid-random";

import DocumentTypeEnum from "../../../../../../enums/DocumentTypeEnum";
import IDocument from "../../../../../../types/IDocument";
import InitialDocumentData from "../../../../../../types/initialData/InitialDocumentData";
import ImageUpload from "../../../../../imageUpload/ImageUpload";
import CmsImage from "../../../../renderer/content/components/CmsImage";
import { CmsContentBlock, CmsContentBlockImage } from "../../../../types/CmsPageData";
import { useCmsEditorContext } from "../../../masks/CmsEditorContext";
import { CmsContentEditor } from "../CmsContentEditor";
import { CmsContentTypeProps } from "./CmsContentTypeProps";

export interface CmsContentImageProps extends CmsContentTypeProps {
    contentNode: CmsContentBlockImage;
}

export function CmsContentImage({
    contentNode,
    editMode,
    startEditMode,
    stopEditMode,
    showLayoutMenu,
}: CmsContentImageProps) {
    const { imagesIndex, setImagesIndex, setFormHasChangesAction, setContentIndex, contentIndex } =
        useCmsEditorContext();

    const updateContentNode = useCallback(
        (newContent: CmsContentBlock) => {
            setContentIndex({
                ...contentIndex,
                [contentNode.id]: {
                    ...newContent,
                } as CmsContentBlock,
            });
            setFormHasChangesAction();
        },
        [contentIndex, setContentIndex, contentNode]
    );

    const handleImageChange = (image: IDocument) => {
        setImagesIndex({ ...imagesIndex, ["" + image.key]: image });
        setFormHasChangesAction();
    };

    const img = imagesIndex[contentNode.data.key];

    useEffect(() => {
        if (typeof contentNode.data != "object") {
            const imgRefId = uuid();

            updateContentNode({
                ...contentNode,
                data: {
                    key: imgRefId,
                    type: DocumentTypeEnum.IMAGE,
                },
            });

            setImagesIndex({
                ...imagesIndex,
                ["" + imgRefId]: {
                    ...InitialDocumentData,
                    key: imgRefId,
                    type: DocumentTypeEnum.IMAGE,
                },
            });
        }

        if (!editMode && img == undefined) {
            startEditMode();
        }
    }, [contentNode.data, editMode]);

    if (!editMode) {
        return <CmsImage onClick={startEditMode} image={img} className="portal-cms-image" />;
    }

    return (
        <CmsContentEditor
            stopEditMode={stopEditMode}
            contentNode={contentNode}
            updateContentNode={updateContentNode}
            showLayoutMenu={showLayoutMenu}
        >
            <ImageUpload
                onChange={handleImageChange}
                docData={img}
                name={contentNode.id}
                label={"Bild"}
                defaultFilename="Bild.jpg"
                hasFreeScale
                withSizeCheck={true}
            />
        </CmsContentEditor>
    );
}
