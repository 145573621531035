import { Grid } from "@material-ui/core";
import React from "react";

import { API_URL_STATIC } from "../../../../../config/config";
import DownloadCard from "../../../../downloadCard/DownloadCard";
import { CmsContentBlockAttachmentList } from "../../../types/CmsPageData";
import { useCmsDataContext } from "../../CmsDataContext";

interface CmsRenderContentAttachmentListProps {
    content: CmsContentBlockAttachmentList;
}

export const CmsRenderContentAttachmentList = (props: CmsRenderContentAttachmentListProps) => {
    const { content } = props;

    const { uploadsIndex } = useCmsDataContext();

    return (
        <Grid container spacing={2} direction="row">
            {content.data.map((ref, index) => {
                const document = uploadsIndex[ref.key];
                return (
                    <Grid key={index + ""} item xs={12} md={6} lg={4}>
                        <DownloadCard
                            tooltip={document?.name}
                            title={document?.title ? String(document.title) : String(document.name)}
                            description={document?.description != null ? document.description : ""}
                            actionButtonText="Herunterladen"
                            actionButtonClassName="App-header-red-button"
                            downloadUrl={API_URL_STATIC + document?.url}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
