import { Grid } from "@material-ui/core";
import React from "react";

import PersonTile from "../../../../personTile/PersonTile";
import { CmsContentBlockPersonList } from "../../../types/CmsPageData";
import { useCmsDataContext } from "../../CmsDataContext";

interface CmsRenderContentPersonListProps {
    content: CmsContentBlockPersonList;
}

export const CmsRenderContentPersonList = (props: CmsRenderContentPersonListProps) => {
    const { content } = props;
    const { teamIndex } = useCmsDataContext();

    return (
        <Grid container spacing={2} direction="row">
            {content.data.map((teamRef, teamIdx) => {
                return (
                    <Grid item xs={12} md={6} lg={6} key={teamIdx}>
                        <PersonTile
                            hasAvatar={teamIndex[teamRef.id]?.image != undefined}
                            minData={false}
                            {...teamIndex[teamRef.id]}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
