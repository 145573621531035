import uuid from "uuid-random";

import CmsPageElementTypeEnum from "../../../../../../enums/CmsPageElementTypeEnum";
import IDocumentRef from "../../../../../../types/IDocumentRef";
import ITeamRef from "../../../../../../types/ITeamRef";
import CmsFaqQuestion from "../../../../types/CmsFaqQuestion";
import { CmsContentBlockText, CmsSectionBlock } from "../../../../types/CmsPageData";
import CmsPageElement, { CmsContent } from "../../../../types/CmsPageLegacy";
import { CmsPageContentTypeEnum } from "./CmsPageContentTypeEnum";

export function mapLegacySectionsToNew(elements: CmsPageElement[]): CmsSectionBlock[] {
    return elements.map((oldFormat) => {
        const pBlock = oldFormat as unknown as Partial<CmsSectionBlock>;
        pBlock.id = uuid();

        switch (oldFormat.type) {
            case CmsPageElementTypeEnum.HEADER_1: {
                pBlock.layout = "l_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: CmsPageContentTypeEnum.HEADER_1,
                        data: oldFormat.content as string,
                    } as CmsContentBlockText,
                ];
                break;
            }
            case CmsPageElementTypeEnum.HEADER_2: {
                pBlock.layout = "l_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: CmsPageContentTypeEnum.HEADER_2,
                        data: oldFormat.content as string,
                    } as CmsContentBlockText,
                ];
                break;
            }
            case CmsPageElementTypeEnum.HEADER_3: {
                pBlock.layout = "l_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: CmsPageContentTypeEnum.HEADER_3,
                        data: oldFormat.content as string,
                    } as CmsContentBlockText,
                ];
                break;
            }
            case CmsPageElementTypeEnum.HTML: {
                pBlock.layout = "l_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: "html",
                        data: oldFormat.content as string,
                    } as CmsContentBlockText,
                ];
                break;
            }
            case CmsPageElementTypeEnum.HTML_TEXT_TEXT: {
                const oldContent = oldFormat.content as string[];

                pBlock.layout = "l_1_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: "html",
                        data: oldContent[0] as string,
                    } as CmsContentBlockText,
                    {
                        id: uuid(),
                        type: "html",
                        data: oldContent[1] as string,
                    } as CmsContentBlockText,
                ];
                break;
            }
            case CmsPageElementTypeEnum.HTML_TEXT_TEXT_TEXT: {
                const oldContent = oldFormat.content as string[];
                pBlock.layout = "l_1_1_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: "html",
                        data: oldContent[0] as string,
                    } as CmsContentBlockText,
                    {
                        id: uuid(),
                        type: "html",
                        data: oldContent[1] as string,
                    } as CmsContentBlockText,
                    {
                        id: uuid(),
                        type: "html",
                        data: oldContent[2] as string,
                    } as CmsContentBlockText,
                ];
                break;
            }
            case CmsPageElementTypeEnum.HTML_IMAGE: {
                const oldContent = oldFormat.content as CmsContent[];

                pBlock.layout = "l_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: "image",
                        data: oldContent[0] as IDocumentRef,
                    },
                ];
                break;
            }
            case CmsPageElementTypeEnum.HTML_IMAGE_IMAGE: {
                const oldContent = oldFormat.content as CmsContent[];

                pBlock.layout = "l_1_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: "image",
                        data: oldContent[0] as IDocumentRef,
                    },
                    {
                        id: uuid(),
                        type: "image",
                        data: oldContent[1] as IDocumentRef,
                    },
                ];
                break;
            }
            case CmsPageElementTypeEnum.HTML_IMAGE_IMAGE_IMAGE: {
                const oldContent = oldFormat.content as CmsContent[];

                pBlock.layout = "l_1_1_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: "image",
                        data: oldContent[0] as IDocumentRef,
                    },
                    {
                        id: uuid(),
                        type: "image",
                        data: oldContent[1] as IDocumentRef,
                    },
                    {
                        id: uuid(),
                        type: "image",
                        data: oldContent[2] as IDocumentRef,
                    },
                ];
                break;
            }
            case CmsPageElementTypeEnum.HTML_TEXT_IMAGE: {
                const oldContent = oldFormat.content as CmsContent[];
                pBlock.layout = "l_1_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: "html",
                        data: oldContent[0] as string,
                    },
                    {
                        id: uuid(),
                        type: "image",
                        data: oldContent[1] as IDocumentRef,
                    },
                ];
                break;
            }
            case CmsPageElementTypeEnum.HTML_IMAGE_TEXT: {
                const oldContent = oldFormat.content as CmsContent[];
                pBlock.layout = "l_1_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: "image",
                        data: oldContent[0] as IDocumentRef,
                    },
                    {
                        id: uuid(),
                        type: "html",
                        data: oldContent[1] as string,
                    },
                ];
                break;
            }
            case CmsPageElementTypeEnum.DOWNLOAD_LIST: {
                const oldContent = oldFormat.content as CmsContent[];
                pBlock.layout = "l_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: CmsPageContentTypeEnum.ATTACHMENT_LIST,
                        data: oldContent[0] as IDocumentRef[],
                    },
                ];
                break;
            }
            case CmsPageElementTypeEnum.TEAM_LIST: {
                const oldContent = oldFormat.content as CmsContent[];
                pBlock.layout = "l_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: CmsPageContentTypeEnum.PERSON_LIST,
                        data: oldContent[0] as ITeamRef[],
                    },
                ];
                break;
            }
            case CmsPageElementTypeEnum.CALCULATOR: {
                pBlock.layout = "l_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: CmsPageContentTypeEnum.CALCULATOR,
                        data: undefined,
                    },
                ];
                break;
            }
            case CmsPageElementTypeEnum.FAQ_LIST: {
                pBlock.layout = "l_1";
                pBlock.content = [
                    {
                        id: uuid(),
                        type: CmsPageContentTypeEnum.FAQ,
                        data: oldFormat.content as CmsFaqQuestion[],
                    },
                ];
                break;
            }
        }

        return pBlock as CmsSectionBlock;
    });
}
