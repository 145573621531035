import "react-image-crop/dist/ReactCrop.css";

import React, { useEffect, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";

import DevLogger from "../../utils/logger/DevLogger";

export type ImageSizeError = {
    isWideEnough: boolean;
    isHighEnough: boolean;
};

interface IImageCropperProps {
    imageToCrop: string;
    onImageCropped: (image: ICroppedImage | null) => void;
    hasFreeScale?: boolean;
    imageName?: string;
    minSourceImageHeight?: number;
    minSourceImageWidth?: number;
    imageExportHeightInPixels?: number;
    imageExportWidthInPixels?: number;
    imageSizeErrorhandler?: (imageSizeError: ImageSizeError) => void;
}

interface IPixelCropData {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface ICroppedImage {
    url: any;
    name: string;
    size: number;
}

const ImageCropper = (props: IImageCropperProps) => {
    const {
        imageToCrop,
        onImageCropped,
        hasFreeScale,
        minSourceImageHeight,
        minSourceImageWidth,
        imageExportHeightInPixels,
        imageExportWidthInPixels,
        imageSizeErrorhandler,
        imageName,
    } = props;
    const [cropConfig, setCropConfig] = useState<Crop>(
        // default crop config
        {
            unit: "%",
            width: 80,
            x: 10,
            y: 20,
            height: 50,
            aspect: hasFreeScale ? undefined : 19 / 10,
        }
    );

    const [imageRef, setImageRef] = useState();

    const cropImage = async (crop: any) => {
        if (imageRef && crop.width && crop.height) {
            const croppedImage = await getCroppedImage(
                imageRef,
                crop,
                imageName || "croppedImage.jpeg" // destination filename
                // destination filename
            );

            // calling the props function to expose
            // croppedImage to the parent component
            onImageCropped(croppedImage);
        }
    };

    useEffect(() => {
        cropImage(cropConfig);
    }, [imageRef, cropConfig]);

    const checkSourceImageSize = (sourceImage: any): ImageSizeError => {
        const sourceImageSizeErrors = {
            isWideEnough: true,
            isHighEnough: true,
        };
        if (
            minSourceImageWidth &&
            sourceImage.naturalWidth < minSourceImageWidth
        ) {
            sourceImageSizeErrors.isWideEnough = false;
        }
        if (
            minSourceImageHeight &&
            sourceImage.naturalHeight < minSourceImageHeight
        ) {
            sourceImageSizeErrors.isHighEnough = false;
        }
        return sourceImageSizeErrors;
    };

    function getCroppedImage(
        sourceImage: any,
        pixelCrop: IPixelCropData,
        fileName: string
    ): Promise<ICroppedImage | null> {
        const sourceImageErros = checkSourceImageSize(sourceImage);
        if (imageSizeErrorhandler) {
            imageSizeErrorhandler(sourceImageErros);
        }
        // creating the cropped image from the source image
        const canvas = document.createElement("canvas");
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        const pixelRatio = window.devicePixelRatio;
        const ctx = canvas.getContext("2d");
        canvas.width = pixelCrop.width * pixelRatio * scaleX;
        canvas.height = pixelCrop.height * pixelRatio * scaleY;
        if (ctx) {
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = "high";
            ctx.drawImage(
                sourceImage,
                pixelCrop.x * scaleX,
                pixelCrop.y * scaleY,
                pixelCrop.width * scaleX,
                pixelCrop.height * scaleY,
                0,
                0,
                pixelCrop.width * scaleX,
                pixelCrop.height * scaleY
            );
        }

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob: any) => {
                    // returning an error
                    if (!blob) {
                        DevLogger.logError("Canvas is empty");
                        return;
                    }
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);
                    let croppedImageUrl = null;
                    const image = new Image();
                    reader.onloadend = () => {
                        let imageAsDataUrl: string =
                            reader.result !== null ? String(reader.result) : "";
                        image.src = imageAsDataUrl;
                        image.onload = () => {
                            if (
                                hasFreeScale == undefined ||
                                hasFreeScale === false
                            ) {
                                const secondCanvas: HTMLCanvasElement =
                                    document.createElement("canvas");

                                let exportHeight;
                                let exportWidth;
                                if (imageExportHeightInPixels !== undefined) {
                                    exportHeight = imageExportHeightInPixels;
                                } else {
                                    exportHeight = image.height;
                                }
                                if (imageExportWidthInPixels != undefined) {
                                    exportWidth = imageExportWidthInPixels;
                                } else {
                                    exportWidth = image.width;
                                }
                                secondCanvas.height = exportHeight;
                                secondCanvas.width = exportWidth;

                                if (secondCanvas !== null && secondCanvas) {
                                    //@ts-ignore
                                    secondCanvas
                                        .getContext("2d")
                                        .drawImage(
                                            image,
                                            0,
                                            0,
                                            exportWidth,
                                            exportHeight
                                        );
                                    imageAsDataUrl =
                                        secondCanvas.toDataURL("image/jpeg");
                                }
                            }
                            croppedImageUrl = {
                                url: imageAsDataUrl,
                                name: fileName,
                                size: blob.size,
                            };

                            resolve(croppedImageUrl);
                        };
                    };
                },
                "image/png",
                1
            );
        });
    }

    return (
        <ReactCrop
            src={imageToCrop}
            crop={cropConfig}
            ruleOfThirds
            onImageLoaded={(imageReference: any) => setImageRef(imageReference)}
            onComplete={(cropConfiguration: any) =>
                cropImage(cropConfiguration)
            }
            onChange={(cropConfiguration) => {
                if (hasFreeScale == undefined || hasFreeScale === false) {
                    cropConfiguration.aspect = 19 / 10;
                    if (cropConfiguration.width !== undefined) {
                        cropConfiguration.height =
                            cropConfiguration.width / (19 / 10);
                    }
                }

                return setCropConfig(cropConfiguration);
            }}
            crossorigin="anonymous" // to avoid CORS-related problems
        />
    );
};

ImageCropper.defaultProps = {
    onImageCropped: () => {
        DevLogger.logInfo("Image cropped");
    },
};

export default ImageCropper;
