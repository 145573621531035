import { Checkbox, FormControl, Grid, InputLabel, Select, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";

import IDocument, { DocumentIndex } from "../../../../types/IDocument";
import ISelectOption from "../../../../xhr/interface/ISelectOption";
import ImageUpload from "../../../imageUpload/ImageUpload";
import { CmsPageData } from "../../types/CmsPageData";

interface CmsPageDataFormProps {
    pageData: CmsPageData;
    handleTextChange: (event: any) => void;
    changeProject: (event: any) => void;
    projectList: ISelectOption[];
    imagesIndex: DocumentIndex;
    handleCheckboxChange: (event: any) => void;
    handlePropertiesTextChange: (event: any) => void;
    handlePropertiesImageChange: (newDocument: IDocument, name: string) => void;
}

export function CmsPageDataForm({
    pageData,
    handleTextChange,
    changeProject,
    projectList,
    imagesIndex,
    handleCheckboxChange,
    handlePropertiesTextChange,
    handlePropertiesImageChange,
}: CmsPageDataFormProps) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    variant="outlined"
                    label="Seiten Titel"
                    name={"title"}
                    value={pageData.data.title ? pageData.data.title : ""}
                    onChange={handleTextChange}
                    fullWidth
                    required={true}
                />
                <TextField
                    variant="outlined"
                    label="Url Schlüssel"
                    name={"urlKey"}
                    value={pageData.data.urlKey ? pageData.data.urlKey : ""}
                    onChange={handleTextChange}
                    fullWidth
                    required={true}
                />

                <FormControl variant="outlined" fullWidth={true}>
                    <InputLabel id="project-select-label">Projekt</InputLabel>
                    <Select
                        labelId="project-select-label"
                        value={pageData.data?.project?.id ? pageData.data?.project?.id : ""}
                        onChange={changeProject}
                        label="Projekt"
                    >
                        <MenuItem key="empty" value="">
                            <em>Bitte Projekt wählen</em>
                        </MenuItem>
                        {projectList.map((project: ISelectOption, index: number) => {
                            return (
                                <MenuItem key={"project-" + index} value={project.id}>
                                    {project.label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={pageData.data.flagPublished}
                                onChange={handleCheckboxChange}
                                name="flagPublished"
                            />
                        }
                        label="Seite ist publiziert"
                    />
                </FormGroup>
            </Grid>

            <Grid item xs={6}>
                {pageData.data?.project?.id && (
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={pageData.data.flagShowTimelineHeader}
                                    onChange={handleCheckboxChange}
                                    name="flagShowTimelineHeader"
                                />
                            }
                            label="Verwenden Sie den Timeline-Header"
                        />
                    </FormGroup>
                )}
                <TextField
                    name="pageDescription"
                    id="outlined-multiline-flexible"
                    label="Beschreibung"
                    fullWidth
                    multiline
                    value={pageData.pageProperties.pageDescription}
                    onChange={handlePropertiesTextChange}
                    variant="outlined"
                />

                <ImageUpload
                    onChange={handlePropertiesImageChange}
                    docData={
                        pageData.pageProperties.headerImage?.key != undefined
                            ? imagesIndex[pageData.pageProperties.headerImage?.key]
                            : undefined
                    }
                    name={"headerImage"}
                    label={"Header Bild"}
                    defaultFilename="Header.jpg"
                    minSourceImageWidth={2000}
                    minSourceImageHeight={1125}
                    imageExportHeightInPixels={1125}
                    imageExportWidthInPixels={2000}
                />
            </Grid>
        </Grid>
    );
}
